<!-- MarkOptionsPopup.vue -->
<template>
  <div
    v-if="visible"
    class="absolute top-4 right-14 bg-dark text-light text-sm p-2 rounded box-shadow z-50 border-mid border-[0.5px]"
  >
    <button class="block w-full text-left hover:text-white p-1" @click.stop="handleMarkAsRead">
      Mark as Read
    </button>
    <button class="block w-full text-left hover:text-white p-1" @click.stop="handleMarkAsUnread">
      Mark as Unread
    </button>
  </div>
</template>

<script setup lang="ts">
defineProps({
  visible: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['markAsRead', 'markAsUnread']);

function handleMarkAsRead() {
  emit('markAsRead');
}

function handleMarkAsUnread() {
  emit('markAsUnread');
}
</script>

<style scoped>
.box-shadow {
  box-shadow: 0 0.5rem 1rem 0.25rem #0b0b0b;
}
</style>
