// mock-notifications.ts
import {NotificationsArray} from './types';

export function getMockCourseNotifications(): NotificationsArray {
  return [
    {
      id: 'course-1',
      heading: 'New Advanced Course Released',
      body: 'Explore our latest bass course focusing on advanced rhythmic techniques and concepts. Enhance your skills with comprehensive guidance.',
      postDate: new Date(new Date().getTime() - 86400000 * 3).toISOString(),
      notificationUrl:
        'https://scottsbasslessons.local/lessons/rhythmic-secrets-to-master-your-swing-feel-lesson-3-walking-bass-lines',
      notificationUrlNewTab: false
      // notificationAvatar: '/avatars/john-patitucci-avatar.jpg'
    },
    {
      id: 'course-2',
      heading: 'Updated Rock Bass Course',
      body: 'The Rock Course has been updated with additional content to further elevate your playing skills. Check it out today.',
      postDate: new Date(new Date().getTime() - 86400000 * 45).toISOString(), // 45 days ago
      notificationUrl:
        'https://scottsbasslessons.local/lessons/rhythmic-secrets-to-master-your-swing-feel-lesson-3-walking-bass-lines',
      notificationUrlNewTab: false
      // notificationAvatar: '/avatars/john-patitucci-avatar.jpg'
    }
  ];
}

export function getMockBlogNotifications(): NotificationsArray {
  return [
    {
      id: 'blog-1',
      heading: 'New Insights on Bass Techniques',
      body: 'Discover valuable insights and techniques to improve your bass playing. Read our latest blog post now.',
      postDate: new Date(new Date().getTime()).toISOString(), // now
      notificationUrl: 'https://scottsbasslessons.local/courses',
      notificationUrlNewTab: false,
      notificationAvatar: '/avatars/scott-devine.jpg'
    },
    {
      id: 'blog-2',
      heading: 'Join the Latest Community Discussion',
      body: 'Engage with fellow bass enthusiasts in our latest community thread. Share your thoughts and learn from others.',
      postDate: new Date(new Date().getTime() - 5 * 60 * 60 * 1000).toISOString(), // 5 hours ago
      notificationUrl: 'https://scottsbasslessons.local/courses',
      notificationUrlNewTab: false,
      notificationAvatar: '/avatars/Anita.jpg'
    },
    {
      id: 'blog-3',
      heading: 'Special Blog Post Announcement',
      body: 'Take advantage of a limited-time offer. Learn more by reading the details in our latest blog post.',
      postDate: new Date(new Date().getTime() - 86400000 * 10).toISOString(), // 10 days ago
      notificationUrl: 'https://scottsbasslessons.local/blog',
      notificationUrlNewTab: false,
      notificationAvatar: '/sign-up-page/sbl-logo.svg'
    },
    {
      id: 'blog-4',
      heading: 'Exciting Updates in Our Latest Blog',
      body: 'Discover new opportunities to enhance your bass-playing experience. Read the latest updates on our platform.',
      postDate: new Date(new Date().getTime() - 86400000 * 30).toISOString(), // 30 days ago
      notificationUrl: 'https://scottsbasslessons.local/groove-trainer/beta',
      notificationUrlNewTab: false,
      notificationAvatar: '/avatars/stu-w.png'
    }
  ];
}