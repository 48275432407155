<template>
  <notification-dropdown
    v-if="dropdownOpen"
    ref="dropdown"
    :focus-mode="focusMode.state.value"
    :guest="guest"
    :server-date="serverDate"
    :user-date-created="userDateCreated"
    :is-narrow="isNarrow"
    @updateFocusMode="handleFocusModeUpdate"
    @updateDropdownOpen="handleDropdownOpen"
  />
  <div v-if="focusMode.state.value">
    <icon-button
      :class="['relative', {'text-white': dropdownOpen}]"
      :focus-mode="focusMode.state.value"
      :icon="iconFocusModeOff"
      :tooltip="'Notifications'"
      :size="size"
      :badge="unreadNotificationsFunction"
      :on-click="toggleDropdown"
    />
  </div>
  <div v-else>
    <icon-button
      :class="['relative', {'text-white': dropdownOpen}]"
      :focus-mode="focusMode.state.value"
      :icon="iconFocusModeOn"
      :tooltip="'Notifications'"
      :size="size"
      :badge="unreadNotificationsFunction"
      :on-click="toggleDropdown"
    />
  </div>
</template>

<script setup lang="ts">
import {computed, ref, PropType, onUnmounted, watch} from 'vue';
import IconButton from '../core/button/IconButton.vue';
import {faBell} from '@fortawesome/pro-solid-svg-icons/faBell';
import {faBellSlash} from '@fortawesome/pro-solid-svg-icons/faBellSlash';
import NotificationDropdown from './NotificationDropdown.vue';
import focusMode from '../vue-composition/message/focus-mode';
import {useNotifications} from '../vue-composition/notifications/notifications';
import {IconButtonSize} from '../core/button/implementation/utils';

const props = defineProps({
  guest: {type: String, required: true},
  serverDate: {type: String, required: true},
  userDateCreated: {type: String, required: true},
  size: {type: String as PropType<IconButtonSize>, default: 'lg'},
  isNarrow: {type: Boolean, default: false}
});

focusMode.initializeFocusMode();

const iconFocusModeOn = computed(() => faBell);
const iconFocusModeOff = computed(() => faBellSlash);
const dropdownOpen = ref(false);
const focusModeVar = ref(focusMode.state.value);

const {unreadNotifications} = useNotifications(
  props.guest,
  props.serverDate,
  props.userDateCreated
);

const unreadNotificationsFunction = computed(() => {
  return focusModeVar.value ? 0 : unreadNotifications.value;
});

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

// Handle focus mode child update event
const handleFocusModeUpdate = (newFocusMode: boolean) => {
  focusModeVar.value = newFocusMode;
  focusMode.setFocusMode(focusModeVar.value);
};

// Handle dropdown child close event
const handleDropdownOpen = (newDropdownOpen: boolean) => {
  dropdownOpen.value = newDropdownOpen;
};

/**
 * Disable scrolling on the body when the dropdown is open on narrow screens:
 *
 * This is necessary because the dropdown is positioned fixed and covers the entire screen
 * and prevents the user from scrolling the body when the dropdown is open.
 */

const dropdown = ref<InstanceType<typeof NotificationDropdown> | HTMLElement | null>(null);

const preventDefault = (event: TouchEvent) => {
  const dropdownEl = dropdown.value instanceof HTMLElement ? dropdown.value : dropdown.value?.$el;
  if (dropdownEl instanceof HTMLElement && dropdownEl.contains(event.target as Node)) {
    return; // Allow scrolling within the dropdown
  }
  event.preventDefault(); // Block scrolling elsewhere
};

watch(dropdownOpen, isOpen => {
  if (isOpen && props.isNarrow) {
    document.body.style.overflow = 'hidden';
    document.addEventListener('touchmove', preventDefault, {passive: false});
  } else {
    document.body.style.overflow = '';
    document.removeEventListener('touchmove', preventDefault);
  }
});

onUnmounted(() => {
  document.body.style.overflow = '';
  document.removeEventListener('touchmove', preventDefault);
});
</script>
