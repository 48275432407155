<template>
  <content-column :width="'wide'">
    <div
      class="relative w-full box-border h-20 grid grid-cols-[auto_1fr_auto] items-center select-none"
    >
      <!-- <div
      class="relative w-full box-border h-20 grid grid-cols-[2fr_1fr_2fr] items-center select-none"
    > -->
      <!-- Navigation menu -->
      <div class="relative w-8 h-8 flex justify-center items-center">
        <icon-button
          :icon="iconBars"
          :on-click="showNavigationMenu"
          :tooltip="'Show menu'"
          :expand="true"
          :color="'white'"
        />
      </div>

      <!-- SBL logo -->
      <div class="flex justify-self-start items-center gap-x-1 flex-nowrap ml-8">
        <!-- <div class="flex justify-center items-center gap-x-1 flex-nowrap"> -->
        <div class="relative w-14 h-14">
          <a href="/">
            <logo :display-type="'solid'" />
          </a>
        </div>
      </div>

      <cluster :gap="'gap-x-fl-xl'" class="w-full flex justify-end">
        <!-- Notifications -->
        <notification-button
          :guest="guest"
          :server-date="serverDate"
          :user-date-created="userDateCreated"
          :size="'xl'"
          :is-narrow="true"
        />
        <!-- User menu -->
        <user-menu :font-size="'text-base'" class="justify-self-end" />
      </cluster>
    </div>
  </content-column>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import {faBars} from '@fortawesome/pro-thin-svg-icons/faBars';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import IconButton from '../../../core/button/IconButton.vue';
import Logo from '../../../generic/logo/Logo.vue';
import UserMenu from '../user-menu/UserMenu.vue';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import Cluster from '../../../core/compositions/Cluster.vue';
import NotificationButton from '../../../notifications/NotificationButton.vue';

export default defineComponent({
  components: {
    ContentColumn,
    IconButton,
    Logo,
    UserMenu,
    Cluster,
    NotificationButton
  },
  props: {
    showNavigationMenu: {type: Function as PropType<ButtonClickCallback>, required: true},
    guest: {type: String, required: true},
    serverDate: {type: String, required: true},
    userDateCreated: {type: String, required: true}
  },
  setup() {
    const iconBars = computed(() => faBars);
    return {
      iconBars
    };
  }
});
</script>
