import {ref} from 'vue';

const state = ref(false);
const localStorageKey = 'focus-mode';

function initializeFocusMode() {
  const savedFocusMode = localStorage.getItem(localStorageKey);
  if (savedFocusMode !== null) {
    state.value = JSON.parse(savedFocusMode);
  }
}

// Function to update `focusMode` and save to local storage
function setFocusMode(value: boolean) {
  state.value = value;
  localStorage.setItem(localStorageKey, JSON.stringify(value));
}

export default {
  state,
  setFocusMode,
  initializeFocusMode
};
