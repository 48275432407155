<template>
  <content-column :width="'wide'">
    <div
      class="relative w-full box-border h-20 grid grid-cols-[2fr_1fr_2fr] items-center select-none"
    >
      <cluster :gap="'gap-x-fl-xl'">
        <!-- Navigation menu -->
        <cluster :gap="'gap-[16px]'" class="cursor-pointer" @click="showNavigationMenu">
          <div class="relative w-8 h-8 flex justify-center items-center">
            <icon-button
              :icon="iconBars"
              :on-click="showNavigationMenu"
              :tooltip="'Show menu'"
              :expand="true"
              :color="'white'"
            />
          </div>
          <div class="text-white font-semibold">Menu</div>
        </cluster>

        <!-- Global search -->
        <link-icon-button
          :icon="iconSearch"
          :url="'/search'"
          :label="'Search'"
          :tooltip="'Search SBL'"
        />
      </cluster>

      <!-- SBL logo -->
      <div class="flex justify-center items-center gap-x-1 flex-nowrap">
        <div class="relative w-14 h-14">
          <a href="/">
            <logo :display-type="'solid'" />
          </a>
        </div>
      </div>

      <cluster :gap="'gap-x-fl-xl'" :class="'justify-self-end'">
        <!-- Bookmarks -->
        <icon-button
          :icon="iconBookmark"
          :url="'/bookmarks'"
          :tooltip="'To my Bookmarks'"
          :size="'lg'"
        />
        <!-- Notifications -->
        <notification-button
          :guest="guest"
          :server-date="serverDate"
          :user-date-created="userDateCreated"
        />
        <!-- User menu -->
        <user-menu :font-size="'text-base'" />
      </cluster>
    </div>
  </content-column>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import IconButton from '../../../core/button/IconButton.vue';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import Logo from '../../../generic/logo/Logo.vue';
import UserMenu from '../user-menu/UserMenu.vue';
import {faBars} from '@fortawesome/pro-thin-svg-icons/faBars';
import {faSearch} from '@fortawesome/pro-light-svg-icons/faSearch';
import {faBookmark} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import NotificationButton from '../../../notifications/NotificationButton.vue';

export default defineComponent({
  components: {
    ContentColumn,
    Cluster,
    IconButton,
    LinkIconButton,
    Logo,
    UserMenu,
    NotificationButton
  },
  props: {
    showNavigationMenu: {type: Function as PropType<ButtonClickCallback>, required: true},
    guest: {type: String, required: true},
    serverDate: {type: String, required: true},
    userDateCreated: {type: String, required: true}
  },
  setup() {
    const iconBars = computed(() => faBars);
    const iconSearch = computed(() => faSearch);
    const iconBookmark = computed(() => faBookmark);
    return {
      iconBars,
      iconSearch,
      iconBookmark
    };
  }
});
</script>
