import {Ref, ref} from 'vue';
import {getAllMessages, getMessageGroups} from '../../../backend/message/message-query';
import {Message, MessageArray, MessageLocation} from '../../../backend/message/message-types';
import {getActiveMessages} from '../../../backend/message/message-utils';
import {getAccessPassesForCurrentUser} from '../../../backend/access-pass/access-pass-query';
import {getSkillLevelOfCurrentUser} from '../../../backend/user/user-query';

export type MessageMap = {
  [location: string]: MessageArray;
};

export type MessageComposition = {
  active: Ref<MessageMap>;
  init: () => Promise<void>;
  get: (location: MessageLocation) => MessageArray | undefined;
  countUnreadMessages: () => number;
};

export function useMessages(
  guest: string,
  userDateCreated: string,
  serverDate: string
): Readonly<MessageComposition> {
  const active = ref<MessageMap>({});

  const init = async () => {
    const userAccessPasses = await getAccessPassesForCurrentUser();
    const userSkillLevel = await getSkillLevelOfCurrentUser();
    const allMessages = await getAllMessages();
    const messageGroups = await getMessageGroups();

    const activeList = getActiveMessages(
      allMessages,
      messageGroups,
      guest,
      userDateCreated,
      serverDate,
      userAccessPasses,
      userSkillLevel
    );

    const result: MessageMap = {};
    activeList.forEach(m => {
      m.messageLocations.forEach(location => {
        // Allow multiple messages to be active for each location
        if (!result[location]) {
          result[location] = [];
        }
        result[location].push(m);
      });
    });

    active.value = result;
  };

  const get = (location: MessageLocation) => {
    return active.value[location];
  };

  const countUnreadMessages = () => {
    return Object.values(active.value)
      .flat() // Flatten to get single array of all messages in all locations
      .filter(message => !isMessageRead(message)).length;
  };

  return {
    active,
    init,
    get,
    countUnreadMessages
  };
}

const readMessages = ref<string[]>([]);
const localStorageKey = 'read-messages';

export function initializeReadMessages() {
  const savedReadMessages = localStorage.getItem(localStorageKey);
  if (savedReadMessages !== null) {
    readMessages.value = JSON.parse(savedReadMessages);
  }
}

export function isMessageRead(message?: Message) {
  return !!message?.id && readMessages.value.includes(message.id);
}

export function isMessageReadById(messageId: string) {
  return readMessages.value.includes(messageId);
}

export function setMessageAsRead(message: Message) {
  if (!readMessages.value.includes(message.id)) {
    readMessages.value.push(message.id);
    localStorage.setItem(localStorageKey, JSON.stringify(readMessages.value));
  }
}

export function setMessageAsReadById(messageId: string) {
  if (!readMessages.value.includes(messageId)) {
    readMessages.value.push(messageId);
    localStorage.setItem(localStorageKey, JSON.stringify(readMessages.value));
  }
}

export function setMessageAsUnread(message: Message) {
  readMessages.value = readMessages.value.filter(id => id !== message.id);
  localStorage.setItem(localStorageKey, JSON.stringify(readMessages.value));
}

export function setMessageAsUnreadById(messageId: string) {
  readMessages.value = readMessages.value.filter(id => id !== messageId);
  localStorage.setItem(localStorageKey, JSON.stringify(readMessages.value));
}

export function setAllMessagesRead(activeMessages: Ref<MessageMap>) {
  const allMessageIds = Object.values(activeMessages.value)
    .flat() // Flatten to get a single array of all messages across all locations
    .map(message => message.id);

  readMessages.value = allMessageIds;
  localStorage.setItem(localStorageKey, JSON.stringify(readMessages.value));
}

export function setAllMessagesUnread() {
  readMessages.value = [];
  localStorage.setItem(localStorageKey, JSON.stringify(readMessages.value));
}
