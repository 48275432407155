/**
 * Notifications Composition
 *
 * Description:
 * This composition is responsible for aggregating all the notifications from different sources.
 * It uses f.e. the useMessages composition to get the message notifications, and other sources
 * could be added in addition.
 *
 * The sources should create an array of notifications as per the Notification type, and store
 * them in a NotificationsArray type.
 *
 * @param guest
 * @param userDateCreated
 * @param serverDate
 * @param notificationsLimit (optional) - Number of latest notifications to be returned
 *
 * @returns - NotificationsArray (list of Notification type objects)
 */

import {computed, Ref, onMounted, ref} from 'vue';
import {NotificationsArray} from './types';
import * as Message from '../message/message';
import {useMessageNotifications} from '../message/message-notifications';
import {getMockBlogNotifications, getMockCourseNotifications} from './mock-notifications';

const DAYS_OLD_LIMIT = 45;
const NOTIFICATIONS_LIMIT = 15;
const DAYS_UNTIL_WEEKS_AGO = 7;

// Set source types
let messages: Message.MessageComposition;

export function useNotifications(
  guest: string,
  userDateCreated: string,
  serverDate: string,
  notificationsLimit?: number
): {notifications: Ref<Readonly<NotificationsArray>>; unreadNotifications: Ref<number>} {
  initializeReadNotifications();

  messages = Message.useMessages(guest, userDateCreated, serverDate);
  Message.initializeReadMessages();

  // Initialize the sources only on mount
  onMounted(async () => {
    await messages.init();
  });

  const notifications = computed(() => {
    const today = new Date();

    const mergedNotifications = [
      ...useMessageNotifications(messages.active.value).value,
      ...getMockCourseNotifications(),
      ...getMockBlogNotifications()
      // Add more sources here...
    ];

    const filteredNotifications = mergedNotifications
      .filter(notification => {
        const postDate = new Date(notification.postDate);
        const daysOld = Math.floor((today.getTime() - postDate.getTime()) / (1000 * 60 * 60 * 24));
        return daysOld <= DAYS_OLD_LIMIT;
      })
      .map(notification => {
        const postDate = new Date(notification.postDate);
        const timeDiff = today.getTime() - postDate.getTime();
        const daysOld = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + 1;
        const hoursOld = Math.floor(timeDiff / (1000 * 60 * 60));

        notification.timeAgo =
          hoursOld < 3
            ? 'Just now!'
            : today.toDateString() === postDate.toDateString()
            ? 'Today'
            : daysOld <= DAYS_UNTIL_WEEKS_AGO
            ? `${daysOld}d ago`
            : `${Math.floor(daysOld / 7)}w ago`;

        notification.timeDiff = timeDiff;

        // Set read status based on local storage
        notification.read = notification.read ?? readNotifications.value.includes(notification.id);

        return notification;
      });

    return filteredNotifications
      .sort((a, b) => (a.timeDiff ?? 0) - (b.timeDiff ?? 0))
      .slice(0, notificationsLimit || NOTIFICATIONS_LIMIT);
  });

  // Count the number of unread notifications within notifications limit
  const unreadNotifications = computed(() => {
    return notifications.value.filter(notification => !notification.read).length;
  });

  return {notifications, unreadNotifications};
}

/**
 * Local Storage Composition for Read Notifications:
 *
 * Description:
 * This composition is responsible for storing a list of read
 * notifications in the user's browser local storage.
 *
 * Usage:
 * Initialize the read notifications on Vue Component mount, and
 * use the getter/setters to manage read/unread notifications.
 */

const readNotifications = ref<string[]>([]);
const localStorageKey = 'read-notifications';

export function initializeReadNotifications() {
  const savedReadNotifications = localStorage.getItem(localStorageKey);
  if (savedReadNotifications !== null) {
    readNotifications.value = JSON.parse(savedReadNotifications);
  }
}

export function setNotificationAsRead(notificationCraftId: string) {
  if (!readNotifications.value.includes(notificationCraftId)) {
    readNotifications.value.push(notificationCraftId);
    localStorage.setItem(localStorageKey, JSON.stringify(readNotifications.value));
  }

  // Update message as read
  Message.setMessageAsReadById(notificationCraftId);
}

export function setNotificationAsUnread(notificationCraftId: string) {
  const index = readNotifications.value.indexOf(notificationCraftId);
  if (index > -1) {
    readNotifications.value.splice(index, 1);
    localStorage.setItem(localStorageKey, JSON.stringify(readNotifications.value));
  }

  // Update message as unread
  Message.setMessageAsUnreadById(notificationCraftId);
}

export function setAllNotificationsAsRead(notifications: Ref<Readonly<NotificationsArray>>) {
  readNotifications.value = notifications.value.map(notification => notification.id);
  localStorage.setItem(localStorageKey, JSON.stringify(readNotifications.value));

  // Reset all messages to read status
  Message.setAllMessagesRead(messages.active);
}

export function setAllNotifictionsAsUnread() {
  readNotifications.value = [];
  localStorage.removeItem(localStorageKey);

  // Reset all messages as unread
  Message.setAllMessagesUnread();
}
