<!-- NotificationsDropdown.vue -->
<template>
  <div ref="dropdown" class="z-50 absolute inset-0 mt-20 -top-2 sm:right-0">
    <div
      :class="[
        'bg-black border-mid text-white text-xs top-0 right-0',
        notificationBoxClasses,
        ...notificationBoxWidth
      ]"
    >
      <!-- Headline Section -->
      <div
        class="font-bold px-5 py-3 border-b-[0.5px] border-dark flex justify-between items-center"
      >
        <h2 class="text-headline font-semibold text-white">Recent Notifications</h2>
      </div>
      <ul
        :class="['notification-list list-none pl-0', ...notificationBoxHeight]"
        @scroll="
          event => {
            handleScrollStart();
            handleEndScroll(event);
          }
        "
      >
        <Notification
          v-for="(notification, index) in notifications"
          :key="index"
          :notification="notification"
          :class="notificationBorder[index]"
          :is-scrolling="isScrolling"
        />
        <li v-if="showScrollEndMessage" class="scroll-end-message text-center py-3 text-mid">
          You have read all recent notifications...
        </li>
      </ul>
      <div class="px-5 pb-4 pt-5 border-t-[0.5px] border-dark flex justify-between items-center">
        <p
          class="cursor-pointer text-sm text-mid hover:text-light"
          :hover="'text-white'"
          @click="clearNotifications"
        >
          Mark all as read
        </p>
        <div class="flex items-center">
          <!-- <p class="text-sm text-neutral-500 font-semibold">View All Notifications</p> -->
          <div class="mr-4">
            <icon-button
              :icon="iconResetNotifications"
              :on-click="resetMessages"
              tooltip="Reset Messages"
            />
          </div>
          <div v-if="focusMode">
            <icon-button
              :focus-mode="focusMode"
              :icon="iconFocusModeOn"
              :color="'primary'"
              :on-click="toggleFocusMode"
              tooltip="Get notifications"
            />
          </div>
          <div v-else>
            <icon-button
              :focus-mode="focusMode"
              :icon="iconFocusModeOff"
              :color="'success'"
              :on-click="toggleFocusMode"
              tooltip="Mute notifications"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, onMounted} from 'vue';
import {faBellOn} from '@fortawesome/pro-solid-svg-icons/faBellOn';
import {faBellSlash} from '@fortawesome/pro-solid-svg-icons/faBellSlash';
import {faTrashUndo} from '@fortawesome/pro-solid-svg-icons/faTrashUndo';
import IconButton from '../core/button/IconButton.vue';
import {useClickOutside} from '../vue-composition/click/click-outside';
import Notification from '../notifications/Notification.vue';
import {
  setAllNotificationsAsRead,
  setAllNotifictionsAsUnread,
  useNotifications
} from '../vue-composition/notifications/notifications';
import {initializeReadNotifications} from '../vue-composition/notifications/notifications';

const props = defineProps({
  focusMode: {type: Boolean, default: false},
  guest: {type: String, required: true},
  serverDate: {type: String, required: true},
  userDateCreated: {type: String, required: true},
  isNarrow: {type: Boolean, default: false}
});

const focusMode = ref(false);
const iconFocusModeOff = ref(faBellOn);
const iconFocusModeOn = ref(faBellSlash);
const iconResetNotifications = ref(faTrashUndo);
const dropdown = ref<HTMLElement | null>(null);
const dropDownClosed = ref(false);
const emit = defineEmits(['updateFocusMode', 'updateDropdownOpen']);
const showScrollEndMessage = ref(false);
const {notifications} = useNotifications(props.guest, props.serverDate, props.userDateCreated);

initializeReadNotifications();

onMounted(() => {
  focusMode.value = props.focusMode;
});

// Dynamic classes for the notification box
const notificationBoxClasses = computed(() => {
  if (props.isNarrow) {
    return ['-mx-5', 'relative', 'items-center', 'justify-center', 'border-t-[0.5px]'];
  } else {
    return [
      'py-1',
      'justify-end',
      'absolute',
      'rounded-xl',
      'border-[1px]',
      'notification-box-shadow'
    ];
  }
});

const notificationBoxWidth = computed(() => {
  return props.isNarrow
    ? ['w-screen', 'max-w-[100vw]']
    : ['w-[450px]', 'min-w-[240px]', 'max-w-[60vw]'];
});

const notificationBoxHeight = computed(() => {
  // For fullscreen, calculate dynamic viewport for mobile browsers
  // For desktop, set fixed max height to 60vh
  return props.isNarrow
    ? ['max-h-[calc(100dvh-181px)]', 'overflow-y-auto']
    : ['max-h-[60vh]', 'overflow-y-auto'];
});

const notificationBorder = computed(() =>
  notifications.value.map((notification, index) => {
    const isLast = index === notifications.value.length - 1;
    const matchesNext = !isLast && notification.read === notifications.value[index + 1].read;

    if (isLast) return 'border-b border-dark';
    return isLast || matchesNext
      ? notification.read
        ? 'border-b border-dark'
        : 'border-b-4 border-black'
      : 'border-none';
  })
);

// Trigger reset of all read messages to get displayed again
const resetMessages = () => {
  setAllNotifictionsAsUnread();
};

// Toggle focus mode (mute/unmute notifications & messages)
const toggleFocusMode = () => {
  focusMode.value = !focusMode.value;
  dropDownClosed.value = false;
  emit('updateFocusMode', focusMode.value);
};

// Close dropdown when clicked outside
useClickOutside(dropdown, () => {
  dropDownClosed.value = !dropDownClosed.value;
  emit('updateDropdownOpen', dropDownClosed.value);
});

const clearNotifications = () => {
  setAllNotificationsAsRead(notifications);
};

// Track scrolling state to inform child components
const isScrolling = ref(false);
const scrollTimeout = ref<NodeJS.Timeout | null>(null);

const handleScrollStart = () => {
  isScrolling.value = true;
  if (scrollTimeout.value) {
    clearTimeout(scrollTimeout.value);
  }
  scrollTimeout.value = setTimeout(() => {
    isScrolling.value = false;
  }, 200); // Debounce time in milliseconds
};

// Show a message when scrolled to the end of notifications
const handleEndScroll = (event: Event) => {
  const target = event.target as HTMLElement;
  const buffer = 35; // Buffer in pixels
  showScrollEndMessage.value =
    target.scrollTop + target.clientHeight >= target.scrollHeight - buffer;
};
</script>

<style scoped>
.notification-box-shadow {
  box-shadow: 0 2rem 3rem 0.5rem #0b0b0b;
}
.notification-list {
  list-style: none;
  /* vertical scrolling props */
  overflow: hidden;
  overflow-y: auto;
  /* Custom scrollbar styling for Firefox */
  scrollbar-width: thin;
  scrollbar-color: theme('colors.mid') theme('colors.dark');
}
.scroll-end-message {
  font-size: 0.87rem;
}
.text-headline {
  font-size: 0.9rem;
  line-height: 1.75rem;
}
/* Webkit browsers (Chrome, Safari, Edge) */
.notification-list::-webkit-scrollbar {
  width: 8px;
}
.notification-list::-webkit-scrollbar-track {
  background: theme('colors.dark');
}
.notification-list::-webkit-scrollbar-thumb {
  background-color: theme('colors.mid');
  border-radius: 4px;
}
</style>
