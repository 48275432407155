/**
 * Utility functions for managing and truncating HTML body content in notifications.
 */
export function truncatedBody(body: string, limit: number, showFull: boolean): string {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = body;
  const plainText = tempElement.textContent || tempElement.innerText || '';

  // If no truncation is needed, return the original HTML
  if (showFull || plainText.length <= limit) {
    return body + ' ';
  }

  let realCharCount = 0;
  let totalCharCount = 0;

  while (realCharCount < limit && totalCharCount < body.length) {
    const currentChar = body[totalCharCount];

    if (currentChar === '<') {
      // Skip over HTML tags
      while (totalCharCount < body.length && body[totalCharCount] !== '>') {
        totalCharCount++;
      }
      totalCharCount++; // Include the closing '>'
    } else if (currentChar === '&' && body.slice(totalCharCount, totalCharCount + 2) === '&#') {
      // Handle emoji entities
      while (totalCharCount < body.length && body[totalCharCount] !== ';') {
        totalCharCount++;
      }
      totalCharCount++;
      realCharCount++;
    } else {
      realCharCount++;
      totalCharCount++;
    }
  }

  return body.slice(0, totalCharCount) + '... ';
}

export function isBodyTruncated(body: string, limit: number): boolean {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = body;
  const plainText = tempElement.textContent || tempElement.innerText || '';
  return plainText.length > limit;
}

export function handleHtmlLinkClick(event: MouseEvent): void {
  const target = event.target as HTMLElement;
  if (target.tagName === 'A') {
    event.stopPropagation(); // Prevent click propagation for <a> tags
  }
}
