import {DateTime} from 'luxon';
import {AccessPassStatus} from '../access-pass/access-pass-types';
import {
  Message,
  MessageConditionsBlock,
  MessageConditionsServerDateIntervalBlock,
  MessageConditionsSignupDateIntervalBlock,
  MessageConditionsVisitorStatusBlock,
  MessageConditionsAccessPassesBlock,
  MessageGroup
} from './message-types';
import {CraftId} from '../craft/craft-types';
import {UserSkillLevel} from '../user/user-query';

function evaluateSignupDate(
  condition: Readonly<MessageConditionsServerDateIntervalBlock>,
  userSignupDateUTC: number
) {
  if (condition.startDate !== null) {
    const date = DateTime.fromISO(condition.startDate).valueOf();
    if (userSignupDateUTC < date) {
      return false;
    }
  }
  if (condition.endDate !== null) {
    const date = DateTime.fromISO(condition.endDate).valueOf();
    if (userSignupDateUTC > date) {
      return false;
    }
  }
  return true;
}

function evaluateServerDate(
  condition: Readonly<MessageConditionsSignupDateIntervalBlock>,
  serverDateUTC: number
) {
  if (condition.startDate !== null) {
    const date = DateTime.fromISO(condition.startDate).valueOf();
    if (serverDateUTC < date) {
      return false;
    }
  }
  if (condition.endDate !== null) {
    const date = DateTime.fromISO(condition.endDate).valueOf();
    if (serverDateUTC > date) {
      return false;
    }
  }
  return true;
}

function evaluateVisitorStatus(
  condition: Readonly<MessageConditionsVisitorStatusBlock>,
  guest: string
): boolean {
  if (condition.visitorStatus === 'guest') {
    return guest.length > 0;
  }
  return guest.length === 0;
}

function evaluateAccessPasses(
  condition: Readonly<MessageConditionsAccessPassesBlock>,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  const toBeConsidered = userAccessPasses
    .filter(p => {
      if (condition.accessPassStatus === 'active') {
        return p.status === 'active' || p.status === 'pending' || p.status === 'cancelling';
      }
      return p.status === condition.accessPassStatus;
    })
    .map(p => p.slug);
  for (let i = 0; i < condition.accessPasses.length; i += 1) {
    const pass = condition.accessPasses[i].slug;
    if (toBeConsidered.includes(pass)) {
      return true;
    }
  }
  return false;
}

function messageIsActive(
  message: Readonly<Message>,
  guest: string,
  userDateCreatedUTC: number | undefined,
  serverDateUTC: number,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  const conditions = message.messageConditions.reduce(
    (status: boolean, condition: Readonly<MessageConditionsBlock>) => {
      if (!status) {
        return false;
      }

      switch (condition.typeHandle) {
        case 'serverDateInterval':
          return evaluateServerDate(
            condition as Readonly<MessageConditionsServerDateIntervalBlock>,
            serverDateUTC
          );
        case 'signupDateInterval': {
          if (userDateCreatedUTC === undefined) {
            return false;
          }
          return evaluateSignupDate(
            condition as Readonly<MessageConditionsSignupDateIntervalBlock>,
            userDateCreatedUTC
          );
        }
        case 'visitorStatus':
          return evaluateVisitorStatus(
            condition as Readonly<MessageConditionsVisitorStatusBlock>,
            guest
          );
        case 'accessPasses':
          return evaluateAccessPasses(
            condition as Readonly<MessageConditionsAccessPassesBlock>,
            userAccessPasses
          );
        default:
          throw new Error('Unknown message conditions block type');
      }
    },
    true
  );
  return conditions;
}

export function getActiveMessages(
  messages: ReadonlyArray<Message>,
  messageGroups: ReadonlyArray<MessageGroup>,
  guest: string,
  userDateCreated: string,
  serverDate: string,
  userAccessPasses: ReadonlyArray<AccessPassStatus>,
  userSkillLevel: Readonly<UserSkillLevel> | null
) {
  const userDateUTC = !guest ? DateTime.fromISO(userDateCreated).valueOf() : undefined;
  const serverDateUTC = DateTime.fromISO(serverDate).valueOf();

  // Get the set of all messages that are in active message groups...
  const groupMessageIds = messageGroups.reduce((msgs: Set<CraftId>, group: MessageGroup) => {
    if (!group.active) {
      return msgs;
    }
    group.messages.forEach(m => {
      msgs.add(m.id);
    });
    return msgs;
  }, new Set<CraftId>());

  const groupMessages = Array.from(groupMessageIds).map(id => {
    const found = messages.find(m => m.id === id);
    if (found === undefined) {
      throw new Error('Internal error');
    }
    return found;
  });

  return groupMessages.filter(m => {
    const isActive = messageIsActive(m, guest, userDateUTC, serverDateUTC, userAccessPasses);

    // Check if the user's skill level matches the message's skill levels
    const matchesSkillLevel =
      !m.messageSkillLevels ||
      m.messageSkillLevels.length === 0 ||
      (userSkillLevel !== null &&
        m.messageSkillLevels.some(skillLevel => skillLevel === userSkillLevel));

    return isActive && matchesSkillLevel;
  });
}
