import {fetchEntries} from '../craft/entry/entry-query';
import {fetchGlobalSet} from '../craft/global-set/global-set-query';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import {Message, MessageGroup, MessageGroups} from './message-types';
import * as QueryBuilder from '../craft/query/craft-query-builder';
import {ThreadSyncCache} from '../../utils/thread-sync-cache';
import {ControllerResponse} from '../craft-action';

const MESSAGE_FIELD = QueryBuilder.matrix('message').blocks([
  QueryBuilder.block('messageHeading').fields([
    QueryBuilder.plainText('messageHeadingText').required()
  ]),
  QueryBuilder.block('messageBody').fields([
    QueryBuilder.plainText('messageBodyParagraphs') // ??? Redactor plugin is no longer supported
  ])
]);

const MESSAGE_CALL_TO_ACTION_FIELD = QueryBuilder.matrix('messageCallToAction').blocks([
  QueryBuilder.block('messageCta').fields([
    QueryBuilder.plainText('messageCtaText').required(),
    QueryBuilder.url('messageCtaUrl').required(),
    QueryBuilder.lightswitch('messageCtaOpenInNewTab').required()
  ])
]);

const MESSAGE_NOTIFICATION_FIELD = QueryBuilder.matrix('messageNotification').blocks([
  QueryBuilder.block('messageNotification').fields([
    QueryBuilder.url('messageNotificationUrl').required(),
    QueryBuilder.lightswitch('messageNotificationNewTab').required(),
    QueryBuilder.plainText('messageNotificationAvatar')
  ])
]);

const MESSAGE_CONDITIONS_SERVER_DATE_BLOCK = QueryBuilder.block('serverDateInterval').fields([
  QueryBuilder.date('startDate'),
  QueryBuilder.date('endDate')
]);
const MESSAGE_CONDITIONS_SIGNUP_DATE_BLOCK = QueryBuilder.block('signupDateInterval').fields([
  QueryBuilder.date('startDate'),
  QueryBuilder.date('endDate')
]);
const MESSAGE_CONDITIONS_VISITOR_STATUS_BLOCK = QueryBuilder.block('visitorStatus').fields([
  QueryBuilder.dropdown('visitorStatus', ['guest', 'loggedIn']).required()
]);
const MESSAGE_CONDITIONS_ACCESS_PASSES_BLOCK = QueryBuilder.block('accessPasses').fields([
  QueryBuilder.categories('accessPasses').required(),
  QueryBuilder.dropdown('accessPassStatus', ['active', 'trial', 'pastDue', 'inactive']).required()
]);
const MESSAGE_CONDITIONS_FIELD = QueryBuilder.matrix('messageConditions').blocks([
  MESSAGE_CONDITIONS_SERVER_DATE_BLOCK,
  MESSAGE_CONDITIONS_SIGNUP_DATE_BLOCK,
  MESSAGE_CONDITIONS_VISITOR_STATUS_BLOCK,
  MESSAGE_CONDITIONS_ACCESS_PASSES_BLOCK
]);

const MESSAGE_FIELDS = [
  IMAGE_COLLECTION_FIELD,
  MESSAGE_CONDITIONS_FIELD,
  MESSAGE_FIELD,
  MESSAGE_CALL_TO_ACTION_FIELD,
  MESSAGE_NOTIFICATION_FIELD,
  QueryBuilder.multiselect('messageLocations', [
    'dashboardCenter',
    'dashboardPromobox',
    'dashboardCourses',
    'dashboardActiveWebcasts',
    'notificationOnly',
    'coursesFilters',
    'coursesCenter'
  ]).required(),
  QueryBuilder.multiselect('messageSkillLevels', [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'noChoice',
    'skippedSurvey'
  ])
];

const MESSAGE_GROUP = QueryBuilder.matrix('messageGroups').blocks([
  QueryBuilder.block('messageGroup').fields([
    QueryBuilder.entries('messages'),
    QueryBuilder.lightswitch('active').required()
  ])
]);

const messagesCache = new ThreadSyncCache<ControllerResponse<ReadonlyArray<Message>>>();
export async function getAllMessages(): Promise<ReadonlyArray<Message>> {
  const result = await fetchEntries<Message>(
    QueryBuilder.entries().section(['messages']).fields(MESSAGE_FIELDS),
    undefined,
    undefined,
    messagesCache,
    'messages'
  );
  return result;
}

const messagesGroupCache = new ThreadSyncCache<ControllerResponse<ReadonlyArray<MessageGroups>>>();
export async function getMessageGroups(): Promise<Array<MessageGroup>> {
  const result = await fetchGlobalSet<MessageGroups>(
    QueryBuilder.globalSet('messageGroups').fields([MESSAGE_GROUP]),
    messagesGroupCache,
    'msgGrps'
  );
  return result.messageGroups;
}
