<template>
  <div class="sticky w-full left-0 top-0 bg-black/90 backdrop-blur z-30 flex justify-center">
    <div class="w-full flex flex-col">
      <site-header-top
        :guest="guest"
        :user-friendly-name="userFriendlyName"
        :server-date="serverDate"
        :user-date-created="userDateCreated"
      />
      <call-to-action :entry-id="entryId" :guest="guest" :segment="segment" />
      <browser-compatibility />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import SiteHeaderTop from './site-header-top/SiteHeaderTop.vue';
import CallToAction from './call-to-action/CallToAction.vue';
import BrowserCompatibility from './browser-compatibility/BrowserCompatibility.vue';

export default defineComponent({
  components: {
    SiteHeaderTop,
    CallToAction,
    BrowserCompatibility
  },
  props: {
    entryId: {type: String, required: true},
    guest: {type: String, required: true},
    userFriendlyName: {type: String, required: true},
    segment: {type: String, default: undefined}, // ??? Added to pass on to temporary SiteHeaderPromoBanner component. Remove when apropriate!
    serverDate: {type: String, required: true},
    userDateCreated: {type: String, required: true}
  },
  setup() {}
});
</script>
